<template>
    <section class="flex h-screen">
        <div class="flex-1">
            <div class="w-full h-full bg_login_img bg-[url('../../assets/images/bg_login.jpg')] bg-cover bg-no-repeat bg-center"></div>
        </div>
        <div class="w-full lg:bg-gray-100 lg:bg-none lg:w-1/2 flex items-center justify-center bg-[url('../../assets/images/bg_login.jpg')] bg-cover bg-no-repeat bg-center">
            <div class="max-w-md md:w-full w-5/6 rounded-lg p-6 backdrop-blur-sm bg-white/20 lg:backdrop-blur-none lg:bg-inherit shadow-md">
                <h1 class="text-2xl mb-6 text-center bg-clip-text text-transparent lg:bg-gradient-to-r lg:from-[#13227d] lg:to-[#56259e]
                bg-gradient-to-r from-gray-100 to-gray-300 border-b border-slate-200/70 pb-4">Recuperar Contraseña</h1>
                <form class="space-y-4" :model="form" ref="form">
                    <div>
                        <label class="text-sm font-medium text-white lg:text-gray-700 text-left flex items-center space-x-2">
                            <font-awesome-icon icon="fa-solid fa-envelope" />
                            <span>Correo</span>
                        </label>
                        <input type="email" v-model="form.email" @change="validateEmail(form.email)" class="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300" :class="validEmail == 2 ? 'bg-red-300 focus:ring-red-300' : ''">
                    </div>
                    <div>
                        <button type="submit" class="w-full bg-gradient-to-r from-[#011039] to-[#221f62] text-white p-2 rounded-md focus:outline-none 
                        transition-colors duration-300 hover:bg-gradient-to-r hover:from-[#221f62] hover:to-[#011039]" v-on:click.prevent="recovery_pass">Recuperar</button>
                    </div>
                </form>
                <div class="mt-4 text-sm text-white lg:text-gray-600 text-center">
                    <router-link to="/login" class="text-black hover:text-[#221f62] font-semibold duration-300 hover:underline">Volver al login</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<style>

</style>

<script>
    import { mapActions } from 'vuex';
    export default {
        data() {
            return {                
                form: {
                    email: '',
                    url: window.location.host + '/crear_nuevapass'
                },
                validEmail: 0
            }
        },
        methods: {
            ...mapActions('auth', { recoveryPass: 'recoveryPass' }),
            recovery_pass() {
                if (this.form.email && this.form.url && this.validEmail == 1) {
                    this.$swal({
                        title: "Advertencia",
                        html: 'Se ha solicitado una recuperación de contraseña para <b>' + this.form.email + '</b>. ¿Desea continuar?',
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#462c95",
                        confirmButtonText: "Aceptar",
                        cancelButtonColor: "#b91c1c",
                        cancelButtonText: 'Cancelar',
                        allowEscapeKey: false,
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.recoveryPass(this.form)
                            .then(resp => {
                                this.$swal({
                                    title: "Recuperación Exitosa",
                                    html: "Se le ha enviado un correo a <b>" + this.form.email + '</b> para recuperar su contraseña.',
                                    icon: "success",
                                    showCancelButton: false,
                                    confirmButtonColor: "#462c95",
                                    confirmButtonText: "Aceptar",
                                    allowEscapeKey: false,
                                    allowOutsideClick: false
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.$router.push({ name: 'login' })
                                    }
                                })
                            })
                            .catch(() => {
                                this.$notify({ type: 'error', title: 'Error', text: 'No se ha podido recuperar contraseña. Valide el correo.' })
                            })
                        }
                        
                    })
                } else {
                    this.$notify({ type: 'error', title: 'Error', text: 'Debe ingresar credenciales válidas.' })
                }
            },
            validateEmail(email) {
                var validate =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

                if (email && validate.test(email)) {
                    this.validEmail = 1
                } else if (email && !validate.test(email)) {
                    this.validEmail = 2
                } else {
                    this.validEmail = 0
                }
            }
        }
    }
</script>