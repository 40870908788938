<template>
    <section>
        <div class="w-full border-b border-slate-200 flex items-center text-xl mt-2 justify-between">

            <div class="flex items-center space-x-2">
                <font-awesome-icon icon="fa-solid fa-gears" />
                <h3 class="py-2 text-left">Lista - Reservas</h3>
            </div>
            <button class="text-sm bg-[#2d90a7] text-white rounded-md space-x-2 px-2 py-1" v-on:click.prevent="showModal({},'create')">
                <font-awesome-icon icon="fa-solid fa-plus" />
                <span>Crear</span>
            </button>
        </div>
        <div class="mt-4">
            <vue-good-table :columns="columns" :rows="allReservas" compactMode styleClass="vgt-table striped">
                <div slot="emptystate">
                    No hay datos para mostrar
                </div>
                <template #table-actions="props">
                </template>
                <template #table-row="props">
                    <span v-if="props.column.field == 'date'">
                        {{ moment(props.row.date).format("DD-MM-YYYY") }}
                    </span>
                    <span v-else-if="props.column.field == 'actions'">
                        <div class="flex items-center space-x-2">
                            <button class="bg-yellow-500 text-white px-2 py-1 duration-300 rounded-sm text-xs hover:bg-yellow-600" @click="showModal(props.row, 'edit')"><font-awesome-icon icon="fa-solid fa-pen-to-square" class="mt-0.5" /> Editar</button>
                            <button class="bg-red-600 text-white px-2 py-1 duration-300 rounded-sm text-xs hover:bg-red-700" @click="EliminarReserva(props.row.id)"><font-awesome-icon icon="fa-solid fa-trash-can" class="mt-0.5" /> Eliminar</button>
                        </div>
                    </span>
                    <span v-else>
                        {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
            </vue-good-table>
            <div id="modalReserve" class="fixed w-full h-wull z-[100] bg-black/80 top-0 bottom-0 left-0 right-0 overflow-auto hidden">
                <div class="bg-white w-11/12 lg:w-3/4 min-h-80 h-auto mt-28 mx-auto rounded-md overflow-hidden">
                    <div class="flex items-center h-14 shadow-md px-4 py-2">
                        <span class="flex-auto w-full text-left">{{ isEditOrCreate == 'create' ? 'Crear una Reserva' : 'Editar Reserva de: ' + objReserve.name }}</span>
                        <span class="flex-auto w-14 text-xl text-gray-600 cursor-pointer" @click="closeModal()">
                            <font-awesome-icon icon="fa-solid fa-xmark" />
                        </span>
                    </div>
                    <div class="w-full min-h-52 h-auto max-h-[600px] flex items-center overflow-y-auto px-4 md:px-8 my-3">
                        <form id="formReserve" ref="form" :model="objReserve" class="space-y-4 grid grid-cols-2 gap-4 w-full">
                            <div class="md:col-span-1 col-span-2">
                                <label class="block text-sm font-medium text-gray-700 text-left mt-4">Nombre *</label>
                                <input type="text" v-model="objReserve.name" class="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300">
                            </div>
                            <div class="md:col-span-1 col-span-2">
                                <label class="block text-sm font-medium text-gray-700 text-left">Teléfono *</label>
                                <input type="text" maxlength="8" v-on:keyup="formatPhoneNumber(objReserve.phone)" v-model="objReserve.phone" class="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300">
                            </div>
                            <div class="md:col-span-1 col-span-2">
                                <label class="block text-sm font-medium text-gray-700 text-left">Correo *</label>
                                <input type="email" v-model="objReserve.email" class="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300">
                            </div>
                            <div class="md:col-span-1 col-span-2">
                                <label class="block text-sm font-medium text-gray-700 text-left">Cant. Personas *</label>
                                <input type="number" v-model="objReserve.persons" class="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300">
                            </div>
                            <div class="md:col-span-1 col-span-2">
                                <label class="block text-sm font-medium text-gray-700 text-left">Fecha *</label>
                                <input id="dateReserve" type="date" v-model="objReserve.date" class="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300">
                            </div>
                            <div class="md:col-span-1 col-span-2">
                                <label class="block text-sm font-medium text-gray-700 text-left">Hora *</label>
                                <select v-model="objReserve.time" class="mt-1 p-2 w-full h-11 border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300">
                                    <option value="" disabled selected>Selecciona una hora</option>
                                    <option v-for="hour in hours" :value="hour">{{ hour }}</option>
                                </select>
                            </div>
                            <div class="col-span-2">
                                <label class="block text-sm font-medium text-gray-700 text-left">Mensaje (opcional)</label>
                                <textarea rows="4" maxlength="180" v-model="objReserve.message" class="mt-1 p-2 w-full resize-none border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"></textarea>
                            </div>
                        </form>
                    </div>
                    <div class="h-14 border-t border-slate-300/50 px-4 py-2">
                        <div class="sm:float-right space-x-2 mr-4 flex sm:block">
                            <button class="flex-auto w-1/2 sm:w-auto bg-[#8a2222] text-white px-3 py-2 rounded-sm text-sm hover:bg-[#441717] duration-300" @click="closeModal()">
                            Cancelar
                            </button>
                            <button class="flex-auto w-1/2 sm:w-auto bg-[#2d90a7] text-white px-3 py-2 rounded-sm text-sm hover:bg-[#2e6c7a] duration-300" v-on:click="isEditOrCreate == 'create' ? submitCreateReserve() : submitEditReserve()">
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<style>
#dateReserve::-webkit-calendar-picker-indicator {
  padding-left: 60%;
}

@media screen and (max-width: 390px) {
    #dateReserve::-webkit-calendar-picker-indicator {
        padding-left: 45%;
    }
}
</style>

<script>
    import dataTable from '@/components/DataTable.vue'
    import moment from 'moment'
    import { mapActions, mapGetters } from 'vuex';

    export default {
        components: {
            dataTable
        },
        data() {
            return {
                objReserve: {},
                selectedHour: null,
                isEditOrCreate: 'create',
                columns: [
                    {
                        label: 'Nombre',
                        field: 'name',
                        sortable: false
                    }, {
                        label: 'Teléfono',
                        field: 'phone',
                        sortable: false
                    }, {
                        label: 'Correo',
                        field: 'email',
                        sortable: false
                    }, {
                        label: 'Personas',
                        field: 'persons',
                        sortable: false
                    }, {
                        label: 'Fecha',
                        field: 'date',
                        sortable: false
                    }, {
                        label: 'Hora',
                        field: 'time',
                        sortable: false
                    }, {
                        label: '',
                        field: 'actions',
                        width: '180px',
                        sortable: false
                    }
                ]
            }
        },
        created: function () {
            this.moment = moment;
        },
        computed: {
            hours() {
                const hours = [];
                for (let hour = 12; hour <= 23; hour++) {
                    hours.push(`${hour % 12 === 0 ? 12 : hour % 12}:00 ${hour >= 12 ? 'pm' : 'am'}`);
                    hours.push(`${hour % 12 === 0 ? 12 : hour % 12}:30 ${hour >= 12 ? 'pm' : 'am'}`);
                }
                return hours;
            },
            ...mapGetters('reservas', { allReservas: 'all' })
        },
        methods: {
            ...mapActions('reservas', { getAllReservas: 'getAll', createReserve: 'createReserve', updateReserve: 'updateReserve', deleteReserve: 'deleteReserve' }),
            getAll() {
                Promise.all([
                    this.getAllReservas()
                ]).then(() => {
                })
            },
            showModal(data, editOrCreate) {
                this.isEditOrCreate = editOrCreate
                let modal = document.getElementById('modalReserve')
                if (editOrCreate == 'edit') {
                    this.objReserve = {
                        id: data.id,
                        date: moment(data.date).format("YYYY-MM-DD"),
                        email: data.email,
                        message: data.message,
                        name: data.name,
                        persons: data.persons,
                        time: data.time,
                        phone: data.phone
                    }
                } else {
                    let fecha = new Date()
                    let fechaMin = fecha.toISOString().split('T')[0]
                    document.querySelector('#dateReserve').min = fechaMin;
                }
                modal.classList.add('block')
                modal.classList.remove('hidden')
            },
            closeModal() {
                let modal = document.getElementById('modalReserve')
                this.objReserve = {}
                modal.classList.remove('block')
                modal.classList.add('hidden')
            },
            submitCreateReserve() {
                if (this.objReserve.name && this.objReserve.email && this.objReserve.date && this.objReserve.time && this.objReserve.persons && this.objReserve.phone) {
                    this.createReserve(this.objReserve)
                    .then((resp) => {
                        this.$notify({
                            title: 'Success',
                            text: 'Se ha creado correctamente el registro.',
                            type: 'success',
                            duration: 5000
                        })
                        document.getElementById("formReserve").reset();
                        this.closeModal()
                        this.getAll(true)
                    }).catch(() => {
                        this.$notify({ type: 'error', title: 'Error', text: 'Error al crear el registro.' })
                    })
                } else {
                    this.$notify({ type: 'error', title: 'Error', text: 'Se deben completar todos los campos obligatorios (*).' })
                }
            },
            submitEditReserve() {
                this.updateReserve(this.objReserve)
                .then((resp) => {
                    this.$notify({
                        title: 'Success',
                        text: 'Se ha modificado correctamente el registro.',
                        type: 'success',
                        duration: 5000
                    })
                    document.getElementById("formReserve").reset();
                    this.closeModal()
                    this.getAll(true)
                }).catch(() => {
                    this.$notify({ type: 'error', title: 'Error', text: 'Error al modificar el registro.' })
                })
            },
            EliminarReserva(id) {
                if (id) {
                    this.$swal({
                        title: "Eliminar Registro",
                        text: "¿Estás seguro de eliminar este registro?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#2d90a7",
                        cancelButtonColor: "#b91c1c",
                        confirmButtonText: "Si, eliminar!",
                        cancelButtonText: "Cancelar",
                        allowEscapeKey: false,
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.deleteReserve(id)
                            .then((resp) => {
                                this.$swal({
                                    title: "Eliminado!",
                                    text: "El registro fue eliminado con exito!",
                                    icon: "success"
                                });
                                this.getAll(true)
                            })
                            
                        }
                    });
                }
            },
            formatPhoneNumber(phone) {

                const phoneNumber = phone.replace(/\D/g, '')

                if (phoneNumber.length < 8) {
                    phone = phoneNumber
                    return
                }

                let formattedPhoneNumber = ''

                if (phoneNumber.length === 8) {
                    formattedPhoneNumber = '(+507) ' + phoneNumber.slice(-8, -4) + '-' + phoneNumber.slice(-4)
                }

                phone = formattedPhoneNumber

                this.objReserve.phone = phone
            }
        },
        mounted() {
            this.getAll()
        }
    }
</script>