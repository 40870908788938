import { createApp } from 'vue'
import './assets/css/tw-style.css'
import App from './App.vue'
import router from './router'
import store from './store'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import Notifications from '@kyvg/vue3-notification'

import VueGoodTablePlugin from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'

import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import {faAngleUp, faChevronLeft, faChevronRight, faMobileScreenButton, faEnvelope, faLocationDot, faClock, faIcons, faGear, faHouse, 
    faList, faAnglesLeft, faBarsStaggered, faArrowRightFromBracket, faGears, faUser, faLock, faTrashCan, faPenToSquare, faEye, faEyeSlash, faXmark, faPlus } from '@fortawesome/free-solid-svg-icons'

import { faInstagram, faTiktok, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { faFloppyDisk } from '@fortawesome/free-regular-svg-icons'
/* add icons to the library */
library.add(faInstagram, faTiktok, faWhatsapp, faAngleUp, faChevronLeft, faChevronRight, faMobileScreenButton, faEnvelope, faLocationDot, faClock, faIcons, faGear, faHouse, 
    faList, faAnglesLeft, faBarsStaggered, faArrowRightFromBracket, faGears, faFloppyDisk, faUser, faLock, faTrashCan, faPenToSquare, faEye, faEyeSlash, faXmark, faPlus)

const app = createApp(App);

app.use(VueSweetalert2)
app.use(Notifications)
app.use(VueGoodTablePlugin)
app.component('EasyDataTable', Vue3EasyDataTable)
app.component('font-awesome-icon', FontAwesomeIcon)

app.use(store)
app.use(router)
app.mount('#app')
