import { createStore } from 'vuex'

import auth from './auth'
import images from './images'
import reservas from './reservas'

export default createStore({
  state: () => ({
  }),
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    images,
    reservas
  }
})
