<template>
    <section class="flex h-screen">
        <div class="flex-1">
            <div class="w-full h-full bg_login_img bg-[url('../../assets/images/bg_login.jpg')] bg-cover bg-no-repeat bg-center"></div>
        </div>
        <div class="w-full lg:bg-gray-100 lg:bg-none lg:w-1/2 flex items-center justify-center bg-[url('../../assets/images/bg_login.jpg')] bg-cover bg-no-repeat bg-center">
            <div class="max-w-md md:w-full w-5/6 rounded-lg p-6 backdrop-blur-sm bg-white/20 lg:backdrop-blur-none lg:bg-inherit shadow-md">
                <h1 class="text-3xl mb-6 text-center bg-clip-text text-transparent lg:bg-gradient-to-r lg:from-[#13227d] lg:to-[#56259e]
                bg-gradient-to-r from-gray-100 to-gray-300 border-b border-slate-200/70 pb-4">Actualizar contraseña</h1>
                <form class="space-y-4" :model="form" ref="form">
                    <div>
                        <label class="text-sm font-medium text-white lg:text-gray-700 text-left flex items-center space-x-2">
                            <font-awesome-icon icon="fa-solid fa-lock" />
                            <span>Nueva Contraseña</span>
                        </label>
                        <div class="relative">
                            <input type="password" id="passNew" v-model="form.newPassword" class="mt-1 pt-2 pb-2 pl-2 pr-11 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300" :class="validPass == 2 ? 'bg-red-300 focus:ring-red-300' : ''">
                            <span class="absolute right-[0.9rem] top-[0.9rem] cursor-pointer" v-on:click="showPassFnc()">
                                <font-awesome-icon icon="fa-solid fa-eye" v-if="!showPass" />
                                <font-awesome-icon icon="fa-solid fa-eye-slash" v-else />
                            </span>
                        </div>
                    </div>
                    <div>
                        <label class="text-sm font-medium text-white lg:text-gray-700 text-left flex items-center space-x-2">
                            <font-awesome-icon icon="fa-solid fa-lock" />
                            <span>Confirmar Contraseña</span>
                        </label>
                        <div class="relative">
                            <input type="password" id="passConfirm" v-model="form.confirmPassword" @change="validatePass(form.newPassword)" class="mt-1 pt-2 pb-2 pl-2 pr-11 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300" :class="validPass == 2 ? 'bg-red-300 focus:ring-red-300' : ''">
                            <span class="absolute right-[0.9rem] top-[0.9rem] text-gray-500">
                                <font-awesome-icon icon="fa-solid fa-eye" v-if="!showPass" />
                                <font-awesome-icon icon="fa-solid fa-eye-slash" v-else />
                            </span>
                        </div>
                    </div>
                    <div>
                        <button type="submit" class="w-full bg-gradient-to-r from-[#011039] to-[#221f62] text-white p-2 rounded-md focus:outline-none 
                        transition-colors duration-300 hover:bg-gradient-to-r hover:from-[#221f62] hover:to-[#011039]" v-on:click.prevent="updatePass">Actualizar</button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<style>

</style>

<script>
    import { mapActions } from 'vuex';
    export default {
        data() {
            return {                
                form: {
                    newPassword: '',
                    confirmPassword: '',                  
                    token: ''
                },
                showPass: 0,
                validPass: 0
            }
        },
        computed: {
            isToken() {
                return {
                    token: this.$route.params.token
                }
            }
        },
        methods: {
            ...mapActions('auth', { updatePassword: 'updatePassword' }),
            updatePass() {
                this.form.token = this.isToken
                if (this.form.newPassword && this.form.confirmPassword && this.form.token && this.validPass == 1) {
                    this.updatePassword(this.form)
                    .then(resp => {
                        this.$swal({
                            title: "Actualizar Contraseña",
                            html: "Se ha actualizado el registro con su nueva contraseña.",
                            icon: "success",
                            showCancelButton: false,
                            confirmButtonColor: "#462c95",
                            confirmButtonText: "Aceptar",
                            allowEscapeKey: false,
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.$router.push({ name: 'login' })
                            }
                        })
                    })
                    .catch(() => {
                        this.$notify({ type: 'error', title: 'Error', text: 'No se ha podido recuperar contraseña. Valide el correo.' })
                    })
                } else {
                    this.$notify({ type: 'error', title: 'Error', text: 'Debe completar los campos y/o las contraseñas deben coincidir.' })
                }
            },
            showPassFnc(val) {
                let passNew = document.getElementById('passNew')
                let passConfirm = document.getElementById('passConfirm')

                if (passNew.type == 'password' && passConfirm.type == 'password') {
                    passNew.type = 'text'
                    passConfirm.type = 'text'
                    this.showPass = 1
                } else {
                    passNew.type = 'password'
                    passConfirm.type = 'password'
                    this.showPass = 0
                }
            },
            validatePass(newPass) {
                if (newPass == this.form.confirmPassword) {
                    this.validPass = 1
                } else if (newPass !== this.form.confirmPassword) {
                    this.validPass = 2
                } else {
                    this.validPass = 0
                }
            }
        },
        mounted() {
        }
    }
</script>