<template>    
    <div class="flex items-center z-50 text-slate-100 bg-gradient-to-r from-[#000000] to-[#775401] shadow-md w-full fixed h-[56px]">
        <a class="flex items-center text-slate-100 space-x-2 px-5 py-3 shadow-md relative">
            <!-- <font-awesome-icon icon="fa-solid fa-gear" class="w-6 h-6" /> -->
            <div class="w-12">
                <img src="../../public/images/logo_albasha_png.png" />
            </div>
            <span class="text-lg text-[#d7d7d7]">Al Basha Admin</span>
        </a>
        <div class="absolute right-5">
            <div class="flex items-center space-x-4">
                <p class="italic text-sm">Hola, {{ userName }} !</p>
                <span class="flex items-center justify-center w-10 h-10 rounded-full bg-[#cb9a25]">
                    <span class="text-slate-900 font-medium text-lg">{{ userName ? userName.substring(0,1) : '' }}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<style>

</style>

<script>
    export default {
        data() {
            return {
                userName: localStorage.getItem("access_user")
            }
        }

    }
</script>