<template>
    <section>
        <div class="w-full border-b border-slate-200 flex items-center text-xl mt-2 justify-between">

            <div class="flex items-center space-x-2">
                <font-awesome-icon icon="fa-solid fa-gears" />
                <h3 class="py-2 text-left">Configuración - Slider</h3>
            </div>

            <button class="text-sm bg-[#2d90a7] text-white rounded-md space-x-2 px-2 py-1" v-on:click.prevent="submit()">
                <font-awesome-icon icon="fa-regular fa-floppy-disk" />
                <span>Guardar</span>
            </button>
        </div>
        <div class="mt-6">
            <form id="formSlider" class="space-y-4 grid grid-cols-2 gap-4" :model="form" ref="form">
                <div class="md:col-span-1 col-span-2">
                    <label class="block text-sm font-medium text-gray-700 text-left">Título</label>
                    <input type="text" v-model="form.titulo" :disabled="allImages.lenght >= 5 ? true : false" class="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300">
                </div>
                <div class="md:col-span-1 col-span-2 !m-0">
                    <label class="block text-sm font-medium text-gray-700 text-left">Imágen</label>
                    <input id="uploadImg" type="file" @change="changeFileImage" accept="image/jpeg,image/jpg,image/png" :disabled="allImages.length >= 5 ? true : false" class="block w-full text-sm file:mr-4 mt-1 file:rounded-md file:border-0 file:bg-[#2d90a7] file:py-2.5 file:px-4 file:text-sm file:font-semibold file:text-white hover:file:bg-[#2e6c7a] focus:outline-none disabled:pointer-events-none disabled:opacity-60" />
                    <p class="text-left mt-1 text-sm text-yellow-500">(Imágenes permitidas: jpeg, jpg y png)</p>
                    <p class="text-left mt-1 text-sm text-red-500" v-if="isTypeImgValid == 2">Tipo de imágen no es válida.</p>
                </div>
                <div class="col-span-2">
                    <label class="block text-sm font-medium text-gray-700 text-left">Descripción</label>
                    <textarea rows="4" id="descMsg" maxlength="180" v-model="form.descripcion" :disabled="allImages.length >= 5 ? true : false" v-on:keyup="countText" class="mt-1 p-2 w-full resize-none border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"></textarea>
                    <div id="contador" class="w-full">
                        <span class="float-right">0/180</span>
                    </div>
                </div>
            </form>
        </div>
        <div class="mt-4">
            <vue-good-table :columns="columns" :rows="allImages" compactMode styleClass="vgt-table striped">
                <div slot="emptystate">
                    No hay datos para mostrar
                </div>
                <template #table-actions="props">
                </template>
                <template #table-row="props">
                    <span v-if="props.column.field == 'base64'">
                        <div v-if="props.row.base64" @click="showModal(props.row)" class="bg-cover bg-no-repeat bg-center cursor-pointer hover:opacity-80 w-16 h-16 rounded-full ring-2 ring-[#2e6c7a] ring-offset-2" :style="props.row.base64 ? 'background-image: url(' + props.row.base64 + ')' : ''"></div>
                    </span>
                    <span v-else-if="props.column.field == 'actions'">
                        <div class="flex items-center space-x-2">
                            <button class="bg-[#2d90a7] text-white px-2 py-1 duration-300 rounded-sm text-xs hover:bg-[#2e6c7a]" :class="!props.row.base64 ? 'bg-gray-400 hover:bg-gray-400 text-slate-700' : ''" :disabled="props.row.base64 ? false : true" @click="showModal(props.row)"><font-awesome-icon icon="fa-solid fa-eye" class="mt-0.5" /> Ver Imagen</button>
                            <button class="bg-red-600 text-white px-2 py-1 duration-300 rounded-sm text-xs hover:bg-red-700" @click="deleteImage(props.row.id)"><font-awesome-icon icon="fa-solid fa-trash-can" class="mt-0.5" /> Eliminar</button>
                        </div>
                    </span>
                    <span v-else>
                        {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
            </vue-good-table>
            <div id="modalImg" class="fixed w-full h-wull z-[100] bg-black/80 top-0 bottom-0 left-0 right-0 overflow-auto hidden">
                <div class="bg-white w-11/12 lg:w-3/4 min-h-80 h-auto mt-28 mx-auto rounded-md overflow-hidden">
                    <div class="flex items-center h-14 shadow-md px-4 py-2">
                        <span class="flex-auto w-full text-left">{{ title_img ? title_img : 'No hay título' }}</span>
                        <span class="flex-auto w-14 text-xl text-gray-600 cursor-pointer" @click="closeModal()">
                            <font-awesome-icon icon="fa-solid fa-xmark" />
                        </span>
                    </div>
                    <div class="w-full min-h-52 h-auto max-h-[600px] flex items-center overflow-y-auto px-8 my-3">
                        <img :src="route_img" :alt="title_img"/>
                        <span class="mx-auto my-0" :class="route_img ? 'hidden' : 'block'">No hay imágen para mostrar</span>
                    </div>
                    <div class="h-14 border-t border-slate-300/50 px-4 py-2">
                        <button class="bg-[#2d90a7] text-white px-3 py-2 rounded-sm text-sm hover:bg-[#2e6c7a] duration-300 float-right" @click="closeModal()">
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </template>
  
  <style>
  
  </style>
  
  <script>
    import dataTable from '@/components/DataTable.vue'
    import { mapActions, mapGetters } from 'vuex';

    export default {
        components: {
            dataTable
        },
        data() {
            return {
                title_img: '',
                route_img: '',
                arrTypeImages: ['image/jpeg', 'image/jpg', 'image/png', 'image/JPEG', 'image/JPG', 'image/PNG'],
                isTypeImgValid: 0,
                form: {
                    titulo: '',
                    descripcion: '',
                    fileImg: null,
                    slider: 1
                },
                columns: [
                    {
                        label: 'Título',
                        field: 'title',
                        tdClass: '!align-middle'
                    }, {
                        label: 'Imágen',
                        field: 'base64',
                        sortable: false,
                        tdClass: '!align-middle'
                    }, {
                        label: 'Descripción',
                        field: 'description',
                        tdClass: '!align-middle'
                    }, {
                        label: '',
                        field: 'actions',
                        width: '220px',
                        sortable: false,
                        tdClass: '!align-middle'
                    }
                ]
            }
        },
        computed: {
            ...mapGetters('images', { allImages: 'all' })
        },
        methods: {
            ...mapActions('images', { uploadImg: 'uploadImage' }),
            ...mapActions('images', { getAllImages: 'getAll', deleteImg: 'deleteImage' }),
            getAll() {
                Promise.all([
                    this.getAllImages('slider')
                ]).then(() => {
                    document.getElementById("formSlider").reset();
                })
            },
            showModal(data) {
                let modal = document.getElementById('modalImg')

                if (data.title && data.base64) {
                    this.title_img = data.title
                    this.route_img = data.base64
                    modal.classList.add('block')
                    modal.classList.remove('hidden')
                }
            },
            deleteImage(id) {
                if (id) {
                    this.$swal({
                        title: "Eliminar Registro",
                        text: "¿Estás seguro de eliminar este registro?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#2d90a7",
                        cancelButtonColor: "#b91c1c",
                        confirmButtonText: "Si, eliminar!",
                        cancelButtonText: "Cancelar",
                        allowEscapeKey: false,
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.deleteImg(id)
                            .then((resp) => {
                                this.$swal({
                                    title: "Eliminado!",
                                    text: "El registro fue eliminado con exito!",
                                    icon: "success"
                                });
                                this.getAll(true)
                            })
                            
                        }
                    });
                }
            },
            closeModal() {
                let modal = document.getElementById('modalImg')

                this.title_img = ''
                this.route_img = ''
                modal.classList.remove('block')
                modal.classList.add('hidden')
            },
            changeFileImage(event) {       
                const file = event.target.files[0];
                if (this.arrTypeImages.indexOf(event.target.files[0].type) >= 0) {
                    if (file) {
                        this.convertToWebP(file)
                        .then((webpBlob) => {
                            webpBlob.name = file.name
                            this.form.fileImg = webpBlob;
                            this.isTypeImgValid = 1
                        })
                        .catch((error) => {
                            this.$notify({ type: 'error', title: 'Error', text: 'Error al convertir la imagen a WebP' })
                        });
                    }
                } else {
                    this.isTypeImgValid = 2
                }
            },
            convertToWebP(imageFile) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                    const img = new Image();
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        canvas.width = img.width;
                        canvas.height = img.height;
                        ctx.drawImage(img, 0, 0);
                        canvas.toBlob((blob) => {
                        resolve(blob);
                        }, 'image/webp');
                    };
                    img.onerror = (error) => {
                        reject(error);
                    };
                    img.src = event.target.result;
                    };
                    reader.onerror = (error) => {
                    reject(error);
                    };
                    reader.readAsDataURL(imageFile);
                });
            },
            countText() {
                const mensaje = document.getElementById('descMsg');
                const contador = document.getElementById('contador');
                mensaje.addEventListener('input', function(e) {
                    const target = e.target;
                    const longitudMax = target.getAttribute('maxlength');
                    const longitudAct = target.value.length;
                    contador.innerHTML = `${longitudAct}/${longitudMax}`;
                });
            },
            submit() {
                if (this.isTypeImgValid && this.isTypeImgValid == 1) {
                    if (this.form.titulo && this.form.fileImg) {
                        this.uploadImg(this.form)
                        .then(resp => {
                            this.$notify({
                                title: 'Success',
                                text: 'Elemento agregado con exito.',
                                type: 'success',
                                duration: 5000
                            })
                            document.getElementById("formSlider").reset();
                            this.getAll()
                        }).catch(() => {
                            this.$notify({ type: 'error', title: 'Error', text: 'No se ha podido agregar el elemento.' })
                        })
                    } else {
                        this.$notify({ type: 'error', title: 'Error', text: 'Debe revisar los datos ingresados.' })
                    }
                } else {
                    this.$notify({ type: 'error', title: 'Error', text: 'Tipo de imágen no es válido.' })
                }
            }        
        },
        mounted() {
            this.getAll()
        }
    }
  </script>
  