<template>
  <notifications position="top right" />
  <div v-if="!isLogin">
    <navbar></navbar>
    <sidebar>
      <template v-slot:paginas>
        <router-view/>
      </template>
    </sidebar>
  </div>
  <div v-else>
      <router-view/>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.vue-notification-group {
  top: 20px!important;
  right: -5px!important;
}

</style>

<script>
  import navbar from '@/components/Navbar.vue'
  import sidebar from '@/components/Sidebar.vue'

  export default {
    components: {
      navbar,
      sidebar
    },
    data() {
      return {
        isLogin: false
      }
    },
    watch: {
      '$route.path': function(val) {
        let path = val.split('/')[1]
        if (path === 'login' || path === 'recuperarpass' || path === 'crear_nuevapass') {
          this.isLogin = true
        } else {
          this.isLogin = false
        }
      }
    }
  }
</script>
