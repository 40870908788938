import axios from "axios";
import { reject } from "core-js";

const axiosWithToken = axios.create();

axiosWithToken.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default {
  namespaced: true,
  state: () => ({
    all: []
  }),
  getters: {
    all: (state) => state.all
  },
  mutations: {
    setAll: (state, data) => {
      if (data != undefined || data != null) {
        state.all = data;
      }
    }
  },
  actions: {
    async getAll({ commit, state, dispatch }) {
      try {
        let response = await axiosWithToken.get(
          process.env.VUE_APP_BASE_URL + "/utility/getAllReserve"
        );
        commit("setAll", response.data.users);
        return response.data.users;
      } catch (e) {
        return Promise.reject(e.response.data.message);
      }
    },
    async createReserve({ commit, state, dispatch }, objeto) {
      let obj = {
            name: objeto.name,
            phone: objeto.phone,
            date: objeto.date,
            time: objeto.time,
            persons: objeto.persons,
            email: objeto.email,
            message: objeto.message
      };
      try {
        let response = await axios.post(
          process.env.VUE_APP_BASE_URL + "/utility/reserve",
          obj
        );
        return response.data;
      } catch(e) {
        return Promise.reject(e.response.data.message)
      }
    },
    async updateReserve({ commit, state, dispatch }, objeto) {
      let obj = {
        date: objeto.date,
        email: objeto.email,
        id: objeto.id,
        message: objeto.message,
        name: objeto.name,
        persons: objeto.persons,
        phone: objeto.phone,
        time: objeto.time,
      }
      try {
        let response = await axiosWithToken.put(
          process.env.VUE_APP_BASE_URL + "/utility/updateReserve/" + objeto.id,
          obj
        )
        return response.data.message
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    },
    async deleteReserve({ commit, state, dispatch }, id) {
      try {
        let response = await axiosWithToken.delete(
          process.env.VUE_APP_BASE_URL + "/utility/deleteReserve/" + id
        );
        return response.data
      } catch(e) {
        return Promise.reject(e.response.data.message)
      }
    }
  }
};
