import axios from "axios";
import { reject } from 'core-js';

const axiosWithToken = axios.create();
const axiosInstanceImg = axios.create();

axiosWithToken.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosInstanceImg.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  config.headers.Authorization = `Bearer ${token}`;
  config.headers["Content-Type"] = "multipart/form-data";
  return config;
});

export default {
  namespaced: true,
  state: () => ({
    // one: {},
    all: []
  }),
  getters: {
    all: state => state.all
  },
  mutations: {
    // setOne: (state, data)=>{
    //     state.one = data
    // },
    setAll: (state, data)=>{
        if(data != undefined || data != null){
            state.all = data
        }
    }
  },
  actions: {
    async uploadImage({ commit, state, dispatch }, objeto) {
      let obj = {
        title: objeto.titulo,
        description: objeto.descripcion,
        slider: objeto.slider,
        image: objeto.fileImg
      };
      try {
        let response = await axiosInstanceImg.post(
          process.env.VUE_APP_BASE_URL + "/image/uploadImage",
          obj
        );
        return response.data;
      } catch(e) {
        return Promise.reject(e.response.data.message)
      }
    },
    // async editImage({ commit, state, dispatch }, objeto) {
    //   let id = objeto.id

    //   let obj = {
    //     title: objeto.title,
    //     description: objeto.description,
    //     slider: objeto.slider,
    //     newImage: objeto.newImage,
    //     image: objeto.fileImg
    //   }
    //   try {
    //     let response = await axiosInstanceImg.put(process.env.VUE_APP_BASE_URL + "/image/updateImage/" + id, obj)
    //     return response.data
    //   } catch(e) {
    //     return Promise.reject(e.response.data.message)
    //   }
    // },
    async getAll({ commit, state, dispatch }, type) {

      let filter = 0
      if (type == 'slider') {
        filter = 1
      } else if (type == 'menu') {
        filter = 0
      }

      try {
        let response = await axios.get(
          process.env.VUE_APP_BASE_URL + "/image/getAllImages/" + filter
        );
        commit('setAll', response.data.data)
        return response.data.data;
      } catch(e) {
        return Promise.reject(e.response.data.message)
      }
    },
    async deleteImage({ commit, state, dispatch }, objeto) {

      try {
        let response = await axiosWithToken.delete(
          process.env.VUE_APP_BASE_URL + "/image/deleteImage/" + objeto
        );
        return response.data
      } catch(e) {
        return Promise.reject(e.response.data.message)
      }
    }
  }
};
