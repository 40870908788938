<template>
    <section class="relative flex min-h-screen">
        <div class="w-64 pt-20 text-slate-100 bg-gradient-to-b from-[#000000] to-[#775401] space-y-6 px-2 md:-translate-x-0 transform -translate-x-full
        transition duration-300 ease-in-out fixed md:relative inset-y-0 left-0 z-40" :class="{ 'fixed !-translate-x-0': showSidebar }">
            <nav id="sidebar" class="text-slate-300">
                <!-- <router-link to="/" class="flex items-center space-x-2 py-3 px-4 hover:bg-slate-100/10 duration-300 cursor-pointer rounded-md" 
                :class="$route.path == '/' ? 'bg-slate-100/10' : ''">
                    <font-awesome-icon icon="fa-solid fa-house" />
                    <span>Dashboard</span>
                </router-link> -->
                <h4 class="h-10 flex items-center text-left text-[#a3a3a3] px-3.5 py-1 text-xs">CONFIGURACIÓN</h4>
                <router-link to="/settings/slider" class="flex items-center space-x-2 py-3 px-4 hover:bg-slate-100/10 duration-300 cursor-pointer rounded-md"
                :class="$route.path == '/settings/slider' ? 'bg-slate-100/10' : ''">
                    <font-awesome-icon icon="fa-solid fa-gears" />
                    <span>Slider</span>
                </router-link>
                <router-link to="/settings/menu" class="flex items-center space-x-2 py-3 px-4 hover:bg-slate-100/10 duration-300 cursor-pointer rounded-md"
                :class="$route.path == '/settings/menu' ? 'bg-slate-100/10' : ''">
                    <font-awesome-icon icon="fa-solid fa-gears" />
                    <span>Menú</span>
                </router-link>
                <router-link to="/settings/reservas" class="flex items-center space-x-2 py-3 px-4 hover:bg-slate-100/10 duration-300 cursor-pointer rounded-md"
                :class="$route.path == '/settings/reservas' ? 'bg-slate-100/10' : ''">
                    <font-awesome-icon icon="fa-solid fa-gears" />
                    <span>Reservas</span>
                </router-link>
                <!-- <a class="flex items-center space-x-2 py-3 px-4 hover:bg-slate-100/10 duration-300 cursor-pointer rounded-md">
                    <font-awesome-icon icon="fa-solid fa-list" />
                    <span>Item 3</span>
                </a>
                <a class="flex items-center space-x-2 py-3 px-4 hover:bg-slate-100/10 duration-300 cursor-pointer rounded-md">
                    <font-awesome-icon icon="fa-solid fa-list" />
                    <span>Item 4</span>
                </a> -->
            </nav>
            <div class="border-t border-slate-200/50 absolute w-full left-0 bottom-0 p-2.5">
                <a class="flex items-center text-slate-300 space-x-2 py-3 px-4 hover:bg-slate-100/10 duration-300 cursor-pointer rounded-md" @click="logout">
                    <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
                    <span>Cerrar Sesión</span>
                </a>
            </div>
            <button class="text-slate-100 block md:hidden absolute rounded-full top-10 -right-11 w-10 h-10 bg-gradient-to-r from-[#011039] to-[#221f62]"
            @click="showSidebar = !showSidebar" :class="{ '!-right-5': showSidebar }">
                <font-awesome-icon icon="fa-solid fa-bars-staggered" v-if="showSidebar == false" />
                <font-awesome-icon icon="fa-solid fa-angles-left" v-else />
            </button>
        </div>
        <div class="flex-1 pt-20">
            <div class="px-5 pb-5">
                <slot name="paginas"></slot>
            </div>
        </div>
    </section>
</template>

<style>

</style>

<script>
    import { ref } from 'vue';
    export default {
        data() {
            return {
            }
        },
        setup() {
            const showSidebar = ref(false)

            return {
                showSidebar
            }
        },
        computed: {
        },
        methods: {
            logout() {
                localStorage.clear()
                this.$router.go('/login')
            }
        },
        mounted() {
        }
    }

</script>