import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

// import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Slider from '../views/Settings/Slider.vue'
import Menu from '../views/Settings/Menu.vue'
import Reservas from '../views/Settings/Reservas.vue'
import RecoveryPass from '../views/RecuperarPass.vue'
import CreatePass from '../views/CrearNuevaPass.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: Home,
  //   meta: {
  //     autenticate: true
  //   }
  // },
  {
    path: '/',
    redirect: to => {
      return { path: '/login' }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/recuperarpass',
    name: 'recuperar_pass',
    component: RecoveryPass
  },
  {
    path: '/crear_nuevapass/:token',
    name: 'crear_nuevapass',
    component: CreatePass
  },
  {
    path: '/settings/slider',
    name: 'settings_slider',
    component: Slider,
    meta: {
      autenticate: true
    }
  },
  {
    path: '/settings/menu',
    name: 'settings_menu',
    component: Menu,
    meta: {
      autenticate: true
    }
  },
  {
    path: '/settings/reservas',
    name: 'settings_reservas',
    component: Reservas,
    meta: {
      autenticate: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const status = store.getters['auth/status']

  const homePage = store.getters['auth/homePage']

  let autorizacion = to.matched.some(record => record.meta.autenticate);
  if(!autorizacion && status){
    next(from.name ? false : homePage)
  }else if(autorizacion && !status){
    next('login');
  }else{
    next();
  }

})


export default router
