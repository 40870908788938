<template>
    <div>
        <vue-good-table :columns="columns" :rows="items" compactMode styleClass="vgt-table striped">
            <div slot="emptystate">
                No hay datos para mostrar
            </div>
            <template slot="table-actions">
                
            </template>
            <template slot="table-row" slot-scope="props">
                <span>
                    {{props.formattedRow[props.column.field]}}
                </span>
            </template>
        </vue-good-table>
        <!-- <EasyDataTable :headers="columns" :items="items" alternating theme-color="#56259e">
            <template #empty-message>
                No hay datos
            </template>
            <template #item-operation="item">
                <button class="px-2 py-1 bg-[#af1e1e] hover:bg-[#a51515] rounded-sm text-white text-md">Eliminar</button>
            </template>
        </EasyDataTable> -->
    </div>
</template>

<style>
	
</style>

<script>
    export default {
        components: {
        },
        props: {
            columns: {
                type: Array,
                required: false,
                default: []
            },
            items: {
                type: Array,
                required: false,
                default: []
            }
        },
        data() {
            return {
            }
        }
    }
</script>