<template>
    <section class="flex h-screen">
        <div class="flex-1 bg-black">
            <div class="w-full h-full bg_login_img bg-[url('../../assets/images/bg_login_2.jpg')] bg-cover bg-no-repeat bg-right opacity-60"></div>
        </div>
        <div class="w-full lg:bg-gray-100 lg:bg-none lg:w-1/2 flex items-center justify-center bg-[url('../../assets/images/bg_login_2.jpg')] bg-cover bg-no-repeat bg-center">
            <div class="max-w-md md:w-full w-5/6 rounded-lg p-6 backdrop-blur-sm bg-white/20 lg:backdrop-blur-none lg:bg-inherit shadow-md">
                <h1 class="text-3xl mb-6 text-center bg-clip-text text-transparent lg:bg-gradient-to-r lg:from-[#775401] lg:to-[#ca9517]
                bg-gradient-to-r from-[#ca9517] to-[#282827] border-b border-slate-200/70 pb-4">Iniciar Sesión</h1>
                <form class="space-y-4" :model="form" ref="form">
                    <div>
                        <label class="text-sm font-medium text-white lg:text-gray-700 text-left flex items-center space-x-2">
                            <font-awesome-icon icon="fa-solid fa-envelope" />
                            <span>Correo</span>
                        </label>
                        <input type="email" v-model="form.email" @change="validateEmail(form.email)" class="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300" :class="validEmail == 2 ? 'bg-red-300 focus:ring-red-300' : ''">
                    </div>
                    <div>
                        <label class="text-sm font-medium text-white lg:text-gray-700 text-left flex items-center space-x-2">
                            <font-awesome-icon icon="fa-solid fa-lock" />
                            <span>Password</span>
                        </label>
                        <div class="relative">
                            <input type="password" id="passLogin" v-model="form.password" class="mt-1 pt-2 pb-2 pl-2 pr-11 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300">
                            <span class="absolute right-[0.9rem] top-[0.9rem] cursor-pointer" v-on:click="showPassFnc()">
                                <font-awesome-icon icon="fa-solid fa-eye" v-if="!showPass" />
                                <font-awesome-icon icon="fa-solid fa-eye-slash" v-else />
                            </span>
                        </div>
                    </div>
                    <div>
                        <button type="submit" class="w-full bg-gradient-to-r from-[#ca9517] to-[#282827] text-white p-2 rounded-md focus:outline-none 
                        transition-colors duration-300 hover:bg-gradient-to-r hover:from-[#775401] hover:to-[#282827]" v-on:click.prevent="submit">Entrar</button>
                    </div>
                </form>
                <div class="mt-4 text-sm text-white lg:text-gray-600 text-center">
                    <p>¿Olvidaste tu contraseña? <router-link to="/recuperarpass" class="text-black hover:text-[#ca9517] font-semibold duration-300 hover:underline">Recuperar contraseña</router-link></p>
                </div>
            </div>
        </div>
    </section>
</template>

<style>

</style>

<script>
    import { mapActions } from 'vuex';
    export default {
        data() {
            return {                
                form: {
                    email: '',
                    password: ''
                },
                showPass: 0,
                validEmail: 0
            }
        },
        methods: {
            ...mapActions('auth', { login: 'login' }),
            submit() {
                if (this.form.email && this.form.password) {
                    this.login(this.form)
                    .then(resp => {
                        this.$notify({
                            title: 'Success',
                            text: 'Inicio de sesión exitosa!',
                            type: 'success',
                            duration: 5000
                        })
                        this.$router.push({path: '/settings/slider'})
                        localStorage.setItem('email', this.form.email)
                    })
                    .catch((error) => {
                        this.$notify({ type: 'error', title: 'Error', text: 'No se ha podido iniciar sesión. Verifíque las credenciales.' })
                    })
                } else {
                    this.$notify({ type: 'error', title: 'Error', text: 'Debe ingresar credenciales válidas.' })
                }
            },
            showPassFnc(val) {
                let passLogin = document.getElementById('passLogin')

                if (passLogin.type == 'password') {
                    passLogin.type = 'text'
                    this.showPass = 1
                } else {
                    passLogin.type = 'password'
                    this.showPass = 0
                }
            },
            validateEmail(email) {
                var validate =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

                if (email && validate.test(email)) {
                    this.validEmail = 1
                } else if (email && !validate.test(email)) {
                    this.validEmail = 2
                } else {
                    this.validEmail = 0
                }
            }
        }
    }
</script>