import axios from 'axios';
import { reject } from 'core-js';

export default {
    namespaced: true,
    state: () => ({
        status: !!JSON.parse(localStorage.getItem('status')),
        token: localStorage.getItem('access_token') || null,
        user: localStorage.getItem('access_user') || {}
    }),
    getters: {
        status: (state) => state.status,
        user: (state) => state.user,
        token: (state) => state.token,
        homePage: () => {    
            return { name: 'settings_slider'}
        }
    },
    mutations: {
        setStatus: (state, value) => {
            if (typeof value === 'boolean') {
                state.status = value
                localStorage.setItem('status', value)
            } else {
                localStorage.removeItem('status')
            }
        },
        setUser: (state, data) => {
            state.user = data
        },
        setToken: (state, data) => {
            state.token = data
        }
    },
    actions: {
        async login({commit, state, dispatch}, objeto) {
            let obj = {
                'email': objeto.email,
                'password': objeto.password
            }
            try {
                let response = await axios.post(process.env.VUE_APP_BASE_URL + '/users' + '/login', obj)
                localStorage.setItem('access_token', response.data.token)
                localStorage.setItem('access_user', response.data.user.username)
                commit('setStatus', true)
                commit('setToken', response.data.token)
                commit('setUser', response.data.user.username)
                return response.data.message
            } catch(e) {
                return Promise.reject(e.response.data.message)
            }
        },
        async recoveryPass({ commit, state, dispatch }, objeto) {
            let obj = {
                'email': objeto.email,
                'url': objeto.url
            }

            try {
                let response = await axios.post(process.env.VUE_APP_BASE_URL + '/users' + '/requestPasswordReset', obj)
                return response.data
            } catch(e) {
                return Promise.reject(e.response.data)
            }
        },
        async updatePassword({ commit, state, dispatch }, objeto) {
            let token = objeto.token.token
            let obj = {
                'newPassword': objeto.newPassword,
                'confirmPassword': objeto.confirmPassword
            }

            try {
                let response = await axios.put(process.env.VUE_APP_BASE_URL + '/users' + '/resetPassword/' + token, obj)
                return response.data
            } catch(e) {
                return Promise.reject(e.response.data.message)
            }
        }
    }
}